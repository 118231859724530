@import 'mixins';

.site-footer{
    width: 100%;
    // padding: 50px 0px;
    // @include flexRow(center);
    @include flexColumn(flex-start);
    background-color: rgb(82, 82, 82);
    // background-color: red;

    &__top-line{
        width: 100%;
        height: 30px;
        // background-color: yellow;
        @include flexRow(center);
        align-items: center;
        border-bottom: 1px solid lighten(rgb(82, 82, 82), 30%);
        // border-color: lighten(rgb(82, 82, 82), 30%);
        padding: 20px 0px 20px 0px;
    }

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: pink;
    }

    &__top-line{
        .site-footer__container{
            @include flexRow(flex-start);
            align-items: center;
        }
    }

    &__logo-block{
        width: 200px;
        height: 30px;
        // background-color: plum;
        @include flexColumn(center);
    }

    &__motto{
        color: white;
        // background-color: plum;
        margin-left: 10px;
        font-size: 18px;
    }

    &__middle-line{
        width: 100%;
        // height: 200px;
        // background-color: yellow;
        @include flexRow(center);
        padding: 20px 0px 20px 0px;
    }

    &__middle-line{
        .site-footer__container{
            @include flexRow(flex-start);
        }
    }

    &__mission{
        width: 300px;
        height: 200px;
        // background-color: plum;
        // font-size: 18px;
        color: lighten(rgb(82, 82, 82), 30%);
    }

    &__menu-block{
        width: 300px;
        // height: 200px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: flex-start;
        list-style: none;
        margin-right: 40px;
    }

    &__menu-header{
        // background-color: orange;
        width: 100%;
        height: 40px;
        font-size: 24px;
        margin-bottom: 5px;
        font-weight: 500;
        @include flexColumn(center);
        color: white;
    }

    &__menu-item{
        // width: calc(100% - 5px);
        // height: 170px;
        // background-color: orange;
        padding: 0px 0px 0px 5px;
        margin-bottom: 5px;
    }

    &__menu-link{
        font-size: 18px;
        text-decoration: none;
        color: lighten(rgb(82, 82, 82), 30%);
        transition: 0.3s;
        &:hover{
            color: white;
        }
    }

    &__bottom-line{
        width: 100%;
        height: 40px;
        // background-color: yellow;
        @include flexRow(center);
        background-color: darken(rgb(82, 82, 82), 5%);
        color: lighten(rgb(82, 82, 82), 30%);
    }

    &__bottom-line{
        .site-footer__container{
            @include flexRow(flex-start);
            align-items: center;
        }
    }
}

@media (max-width: 1200px){
    .site-footer{
        &__top-line{
            height: unset;
            .site-footer__container{
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &__motto{
            text-align: center;
        }

        &__middle-line{
            .site-footer__container{
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }
        }

        &__menu-header{
            text-align: center;
        }

        &__menu-block{
            margin: 10px;
            align-items: center;
        }

        &__bottom-line{
            .site-footer__container{
                // text-align: center;
                justify-content: center;
            }
        }
    }
}